<template>
    <div
        v-if="loader"
        class="loader"
    />
    <div
        v-else-if="!compareData"
        class="revisions-viewer__compare revisions-viewer__no-compare"
    >
        <span>
            <file-difference />
        </span>
        <span>Please, select two versions to see the difference</span>
    </div>
    <div
        v-else
        ref="content"
        class="revisions-viewer__compare"
    >
        <div class="post-data__block">
            <div class="post-data__block--differences">
                Differences between versions
                <span> <span v-if="hasLoadMore">more then</span>&nbsp;{{ diffCounter }}</span>
            </div>
        </div>
        <div v-html="compareData" />
        <div
            v-if="hasLoadMore"
            class="small-loader"
        />
    </div>
</template>

<script>
import FileDifference from '@/icons/FileDifference';
import { debounce } from 'lodash';

export default {
    name: 'RevisionsCompare',
    components: {
        FileDifference
    },
    props: {
        compareData: String,
        diffCounter: Number,
        loader: Boolean,
        hasLoadMore: Boolean
    },
    watch: {
        compareData(val) {
            if (!val) { return }
            this.$nextTick(() => {
                this.$refs.content.addEventListener('scroll', debounce((e) => {
                    const { scrollTop, scrollHeight, clientHeight } = e.target
                    const maxScroll = scrollHeight - clientHeight
                    if (maxScroll - scrollTop < 300) {
                        this.$emit('load-more')
                    }
                }, 500))
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .revisions-viewer__compare {
        padding: 12px;
    }
    .revisions-viewer__no-compare {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-wrap: wrap;
        span {
            width: 100%;
            svg {
                margin-bottom: 16px;
            }
        }
    }
    .loader {
        width: 100%;
        height: 100%;
        background: url('/img/loader.svg') no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        animation: none;
        border-radius: 0;
    }
    .small-loader {
        background: url('/img/loader-small.svg') no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        animation: none;
        border-radius: 0;
        position: relative;
        width: 100px;
        height: 100px;
        margin: auto;
    }
</style>
