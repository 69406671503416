<template>
    <svg
        width="63"
        height="63"
        viewBox="0 0 63 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0)">
            <path
                d="M47.25 63C38.5639 63 31.5 55.9361 31.5 47.25C31.5 46.5255 32.088 45.9375 32.8125 45.9375C33.537 45.9375 34.125 46.5255 34.125 47.25C34.125 54.4871 40.0129 60.375 47.25 60.375C54.4871 60.375 60.375 54.4871 60.375 47.25C60.375 40.0129 54.4871 34.125 47.25 34.125C43.3073 34.125 39.6191 35.8628 37.1332 38.8946C36.6739 39.4538 35.847 39.5325 35.2853 39.0757C34.7261 38.6164 34.6448 37.7895 35.1041 37.2278C38.0914 33.5895 42.5171 31.5 47.25 31.5C55.9361 31.5 63 38.5639 63 47.25C63 55.9361 55.9361 63 47.25 63Z"
                fill="#4F4F4F"
            />
            <path
                d="M40.6875 39.375H35.4375C34.713 39.375 34.125 38.787 34.125 38.0625V32.8125C34.125 32.088 34.713 31.5 35.4375 31.5C36.162 31.5 36.75 32.088 36.75 32.8125V36.75H40.6875C41.412 36.75 42 37.338 42 38.0625C42 38.787 41.412 39.375 40.6875 39.375Z"
                fill="#4F4F4F"
            />
            <path
                d="M24.9375 55.125H6.5625C2.94263 55.125 0 52.1824 0 48.5625V14.4375C0 10.8176 2.94263 7.875 6.5625 7.875H11.8125C12.537 7.875 13.125 8.463 13.125 9.1875C13.125 9.912 12.537 10.5 11.8125 10.5H6.5625C4.39163 10.5 2.625 12.2666 2.625 14.4375V48.5625C2.625 50.7334 4.39163 52.5 6.5625 52.5H24.9375C25.662 52.5 26.25 53.088 26.25 53.8125C26.25 54.537 25.662 55.125 24.9375 55.125Z"
                fill="#4F4F4F"
            />
            <path
                d="M30.1875 15.75H14.4375C12.2666 15.75 10.5 13.9834 10.5 11.8125V6.5625C10.5 5.838 11.088 5.25 11.8125 5.25H15.8813C16.4903 2.2575 19.1441 0 22.3125 0C25.4809 0 28.1347 2.2575 28.7437 5.25H32.8125C33.537 5.25 34.125 5.838 34.125 6.5625V11.8125C34.125 13.9834 32.3584 15.75 30.1875 15.75ZM13.125 7.875V11.8125C13.125 12.5344 13.7156 13.125 14.4375 13.125H30.1875C30.9094 13.125 31.5 12.5344 31.5 11.8125V7.875H27.5625C26.838 7.875 26.25 7.287 26.25 6.5625C26.25 4.39162 24.4834 2.625 22.3125 2.625C20.1416 2.625 18.375 4.39162 18.375 6.5625C18.375 7.287 17.787 7.875 17.0625 7.875H13.125Z"
                fill="#4F4F4F"
            />
            <path
                d="M35.4375 23.625H9.1875C8.463 23.625 7.875 23.037 7.875 22.3125C7.875 21.588 8.463 21 9.1875 21H35.4375C36.162 21 36.75 21.588 36.75 22.3125C36.75 23.037 36.162 23.625 35.4375 23.625Z"
                fill="#4F4F4F"
            />
            <path
                d="M27.5625 31.5H9.1875C8.463 31.5 7.875 30.912 7.875 30.1875C7.875 29.463 8.463 28.875 9.1875 28.875H27.5625C28.287 28.875 28.875 29.463 28.875 30.1875C28.875 30.912 28.287 31.5 27.5625 31.5Z"
                fill="#4F4F4F"
            />
            <path
                d="M24.9375 39.375H9.1875C8.463 39.375 7.875 38.787 7.875 38.0625C7.875 37.338 8.463 36.75 9.1875 36.75H24.9375C25.662 36.75 26.25 37.338 26.25 38.0625C26.25 38.787 25.662 39.375 24.9375 39.375Z"
                fill="#4F4F4F"
            />
            <path
                d="M43.3125 23.625C42.588 23.625 42 23.037 42 22.3125V14.4375C42 12.2666 40.2334 10.5 38.0625 10.5H32.8125C32.088 10.5 31.5 9.912 31.5 9.1875C31.5 8.463 32.088 7.875 32.8125 7.875H38.0625C41.6824 7.875 44.625 10.8176 44.625 14.4375V22.3125C44.625 23.037 44.037 23.625 43.3125 23.625Z"
                fill="#4F4F4F"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="63"
                    height="63"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'FileDifference'
}
</script>

<style scoped>

</style>
