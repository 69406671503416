<template>
    <div
        :class="isShowedSidebar ? 'sidebar-opened' : 'sidebar-closed'"
        class="content-layout-wrapper"
    >
        <div class="content revisions-content">
            <div class="revisions-header">
                <slot name="header" />
            </div>
            <div class="revisions-wrapper">
                <div class="revisions-list viewer-bordered">
                    <h3 class="revisions-list__title">
                        Revisions
                        <p class="revisions-list__count">
                            {{ revisions_count }}
                        </p>
                    </h3>
                    <slot name="list" />
                </div>
                <div class="revisions-viewer">
                    <div class="revisions-viewer__post viewer-bordered">
                        <slot name="post-one" />
                    </div>
                    <div class="revisions-viewer__post viewer-bordered">
                        <slot name="post-two" />
                    </div>
                    <div class="revisions-viewer__compare viewer-bordered">
                        <slot name="compare" />
                    </div>
                </div>
            </div>
        </div>
        <slot name="modals" />
    </div>
</template>

<script>
export default {
    name: 'RevisionsLayout',
    props: {
        revisions_count: Number
    },
    data() {
        return {
            isShowedSidebar: true
        }
    },
    mounted() {
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    beforeDestroy() {
        this.$bus.$off('toggleSidebar')
    }
}
</script>

<style lang='scss'>
    @import "@/assets/styles/dashboard/components/content/content-layout";
    .content-layout-wrapper .content.revisions-content {
        background: unset;
        max-height: calc(100vh - 80px);
        overflow-y: hidden;
    }
    .viewer-bordered {
        border-radius: 4px
    }
    .revisions-wrapper {
        display: flex;
        justify-content: space-between;
        .revisions-list {
            width: 260px;
            background: #fff;
            overflow-y: scroll;
            max-height: 76.5vh;
            padding: 20px;
            &__title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 23px;
                color: #1f2939;
                margin-bottom: 19px;
                display: flex;
                align-items: center;
            }
            &__count {
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(79, 79, 79, 0.1);
                border-radius: 4px;
                margin-left: 15px;
            }
        }
        .revisions-viewer {
            width: calc(100% - 275px);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 100%;
            &__post {
                height: 38vh;
                overflow-y: scroll;
                background: #fff;
                width: 49.5%;
                margin-bottom: 16px;
            }
            &__compare {
                height: calc(37vh - 1px);
                overflow-y: scroll;
                background: #fff;
                width: 100%;
            }
        }
    }
</style>
