import CustomRevisions from '@/components/CustomRevisions';

export default {
    components: {
        CustomRevisions
    },
    data() {
        return {
            selectedItems: []
        }
    },
    methods: {
        addSelectedItem(event) {
            const index = this.selectedItems.findIndex((item) => item === event)
            if (index === -1) {
                if (this.selectedItems.length === 2) return
                this.selectedItems.push(event)
            } else {
                this.selectedItems.splice(index, 1)
            }
        }
    }
}
