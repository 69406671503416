<template>
    <modal-layout
        v-if="dialog"
        :name="name"
        :hidden="hidden"
        :size="'small'"
        class="feedback_modal"
        :style="{ 'z-index': order }"
        @closeModal="close"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <p class="feedback_modal__body-title">
                <b>
                    {{ activeTab+1 }} of {{ tabs.length }}
                </b>
                {{ tabs[activeTab].title }}
            </p>
        </template>
        <template #content>
            <!-- tab 0 : website -->
            <div
                v-show="activeTab===0"
                class="feedback_modal__body"
            >
                <div>
                    <feedback-buttons
                        v-model="chk_links"
                        @buttonClick="answerButtonClick($event, tabs[activeTab].variableName)"
                    />
                    <div
                        v-show="chk_links === false"
                        class="feedback_modal__body-rating_text"
                    >
                        <form-textarea
                            v-model="$v.comment_links.$model"
                            :legend="tabs[3].question"
                            type="text"
                            :name="tabs[3].question"
                            :field.sync="comment_links"
                            :errors="validationMessage($v.comment_links)"
                            @input.native="$v.comment_links.$touch()"
                            @blur.native="$v.comment_links.$touch()"
                        />
                    </div>
                </div>
            </div>
            <!-- tab 0 : website -->

            <!-- tab 1 : communication -->
            <div
                v-show="activeTab===1"
                class="feedback_modal__body"
            >
                <div>
                    <feedback-buttons
                        v-model="chk_content"
                        @buttonClick="answerButtonClick($event, tabs[activeTab].variableName)"
                    />
                    <div
                        v-show="chk_content === false"
                        class="feedback_modal__body-rating_text"
                    >
                        <form-textarea
                            v-model="$v.comment_content.$model"
                            :legend="tabs[2].question"
                            type="text"
                            :name="tabs[2].question"
                            :field.sync="comment_content"
                            :errors="validationMessage($v.comment_content)"
                            @input.native="$v.comment_content.$touch()"
                            @blur.native="$v.comment_content.$touch()"
                        />
                    </div>
                </div>
            </div>
            <!-- tab 1 : communication -->

            <!-- tab 2 : content -->
            <div
                v-show="activeTab===2"
                class="feedback_modal__body"
            >
                <div>
                    <feedback-buttons
                        v-model="chk_grammar"
                        @buttonClick="answerButtonClick($event, tabs[activeTab].variableName)"
                    />
                    <div
                        v-show="chk_grammar === false"
                        class="feedback_modal__body-rating_text"
                    >
                        <form-textarea
                            v-model="$v.comment_grammar.$model"
                            :legend="tabs[1].question"
                            type="text"
                            :name="tabs[1].question"
                            :field.sync="comment_grammar"
                            :errors="validationMessage($v.comment_grammar)"
                            @input.native="$v.comment_grammar.$touch()"
                            @blur.native="$v.comment_grammar.$touch()"
                        />
                    </div>
                </div>
            </div>
            <!-- tab 2 : content -->

            <!-- tab 3 : delivery -->
            <div
                v-show="activeTab===3"
                class="feedback_modal__body"
            >
                <div>
                    <feedback-buttons
                        v-model="chk_photo"
                        @buttonClick="answerButtonClick($event, tabs[activeTab].variableName)"
                    />
                    <div
                        v-show="chk_photo === false"
                        class="feedback_modal__body-rating_text"
                    >
                        <form-textarea
                            v-model="$v.comment_photo.$model"
                            :legend="tabs[0].question"
                            type="text"
                            :name="tabs[0].question"
                            :field.sync="comment_photo"
                            :errors="validationMessage($v.comment_photo)"
                            @input.native="$v.comment_photo.$touch()"
                            @blur.native="$v.comment_photo.$touch()"
                        />
                    </div>
                </div>
            </div>
            <!-- tab 3 : delivery -->

            <div class="feedback_modal__progress">
                <span
                    :style="`width: ${progress}%`"
                />
            </div>
        </template>
        <template #buttons>
            <!--<button-->
            <!--    v-if="activeTab === 0"-->
            <!--    class="btn-base btn-base_normal btn-base_border"-->
            <!--    @click="close"-->
            <!--&gt;-->
            <!--    Close-->
            <!--</button>-->
            <custom-button
                v-if="activeTab > 0"
                default
                type="button"
                @on-btn-click="previousStep()"
            >
                Back
            </custom-button>
            <button
                v-if="activeTab < 3"
                :disabled="activeTab === 3 || !isCurrentStepValid"
                class="btn-base btn-base_normal btn-base_colored feedback_modal__send"
                @click.prevent="nextStep(tabs[activeTab].name)"
            >
                Next
            </button>
            <button
                v-else
                :disabled="!isCurrentStepValid"
                class="btn-base btn-base_normal btn-base_colored feedback_modal__send"
                @click.prevent="validate"
            >
                Send
            </button>
        </template>
    </modal-layout>
</template>

<script>

import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/feedback/Rules'
import { formMessages } from '@/validation/feedback/Message'
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

import { TABS } from '@/helpers/constants/modals/feedback-tabs';

import {
    ACTION_CREATE_REVIEW
} from '@/store/modules/content/reviews/action-types'
import { createNamespacedHelpers } from 'vuex';
import FeedbackButtons from './components/Feedback/FeedbackButtons';

const {
    mapActions: mapReviewsActions
} = createNamespacedHelpers('reviews');

export default {
    validations: {
        ...validationRules
    },
    components: {
        FeedbackButtons
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        modalsEventBusMixins
    ],
    props: {
        row: {
            type: Object
        },
        name: String
    },
    data: () => ({
        dialog: false,
        activeTab: 0,
        tabs: TABS,
        revision_type: '',
        chk_links: null,
        comment_links: '',
        chk_content: null,
        comment_content: '',
        chk_grammar: null,
        comment_grammar: '',
        chk_photo: null,
        comment_photo: ''
    }),
    computed: {
        progress() {
            const calculatedProgress = ((this.activeTab) / this.tabs.length) * 100
            return calculatedProgress
        },
        isCurrentStepValid() {
            switch (this.activeTab) {
            case 0:
                return !this.$v.chk_links.$invalid && !this.$v.comment_links.$invalid
            case 1:
                return !this.$v.chk_content.$invalid && !this.$v.comment_content.$invalid
            case 2:
                return !this.$v.chk_grammar.$invalid && !this.$v.comment_grammar.$invalid
            case 3:
                return !this.$v.chk_photo.$invalid && !this.$v.comment_photo.$invalid
            default:
                return false
            }
        }
    },
    watch: {
        delivery(val) {
            if (+val >= 4) {
                this.comment_links = ''
            }
        },
        content(val) {
            if (+val >= 4) {
                this.comment_content = ''
            }
        },
        communication(val) {
            if (+val >= 4) {
                this.comment_grammar = ''
            }
        },
        website(val) {
            if (+val >= 4) {
                this.comment_photo = ''
            }
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapReviewsActions([
            ACTION_CREATE_REVIEW
        ]),
        answerButtonClick(event, variableName) {
            if (event) {
                this[variableName] = event
                // if (this.activeTab < this.tabs.length - 1) this.activeTab += 1
                return
            }
            this[variableName] = event
        },
        open() {
            this.dialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        nextStep(name) {
            this.$v.$reset()
            if ((name === 'Links') && !this.$v.chk_links.$invalid && !this.$v.comment_links.$invalid) {
                this.activeTab += 1
            } else if (name === 'Content' && !this.$v.chk_content.$invalid && !this.$v.comment_content.$invalid) {
                this.activeTab += 1
            } else if (name === 'Grammar' && !this.$v.chk_grammar.$invalid && !this.$v.comment_grammar.$invalid) {
                this.activeTab += 1
            } else if (name === 'Photo' && !this.$v.chk_photo.$invalid && !this.$v.comment_photo.$invalid) {
                this.activeTab += 1
            } else {
                this.$v.$touch()
                this.$bus.$emit('showSnackBar', 'Please complete the form', 'error');
            }
        },
        previousStep() {
            if (this.activeTab > 0) {
                this.activeTab -= 1
            }
        },
        async submit() {
            const vm = this
            vm.loading = true

            const data = {
                chk_links: this.chk_links ? 1 : 0,
                comment_links: this.comment_links,
                chk_content: this.chk_content ? 1 : 0,
                comment_content: this.comment_content,
                chk_grammar: this.chk_grammar ? 1 : 0,
                comment_grammar: this.comment_grammar,
                chk_photo: this.chk_photo ? 1 : 0,
                comment_photo: this.comment_photo,
                revision_type: this.data.post.type,
                revision_id: this.data.post.rev_id,
                post_id: this.data.post.post_id
            }

            Object.entries(data).forEach(([key, value]) => {
                if (value === '') {
                    delete data[key]
                }
            });
            this[ACTION_CREATE_REVIEW](data)
                .then(() => {
                    this.cancelModal()
                    this.clearAllFields()
                    this.$bus.$emit('showSnackBar', 'Your evaluation is successfully saved!', 'success')
                })
        },
        clearAllFields() {
            this.$v.$reset()
            this.chk_links = null
            this.comment_links = ''
            this.chk_content = null
            this.comment_content = ''
            this.chk_grammar = null
            this.comment_grammar = ''
            this.chk_photo = null
            this.comment_photo = ''
            this.activeTab = 0
        },
        close() {
            this.clearAllFields()
            this.cancelModal()
        }
    }
}
</script>

<style lang="scss">
    .feedback_modal .feedback_modal__send:disabled {
        background: #F2F2F2 !important;
        color: #1F2939 !important;
        opacity: 0.6 !important;
    }
    .feedback_modal{
        .v-modal__header {
            padding-right: 35px;
        }
        .feedback_modal__send {
            &:disabled {
                background: rgba(255, 162, 59, 0.3);
                cursor: default;
            }
        }
        .v-modal__content {
            min-height: 100px !important;
        }
        &-content{
            max-width: 500px;
            width: 100%;
            background-color: #fff;
            padding: 20px;
            position: relative;
        }
        &__close{
            position: absolute;
            right: -3px;
            top: 0px;
            cursor: pointer;
        }
        &__header{
            position: relative;
            &-data{
                display: flex;
                .avatar-wrapper{
                    margin-right: 20px;
                }
            }
            &-text{
                font-size: 16px;
                line-height: 22px;
                // color: $font-color-main;
                p {
                    display: flex;
                    align-items: center;
                    margin: 5px 0;
                    span {
                        font-weight: 700;
                        // color: $font-color-main;
                        margin-right: 10px;
                    }
                    img{
                        margin-right: 10px;
                    }
                }
            }
        }
        &__body{
            position: relative;
            width: 100%;
            &-title{
                font-size: 14px !important;
                // color: $font-color-main;
                font-weight: 700;
            }
            &-rating{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &_text {
                    margin-top: 24px;
                }
            }
        }
        &__btns{
            .btn-base{
                background: #fff;
                border: 2px solid $orange;
                &:disabled{
                    background: rgba(0,0,0,.12);
                    border: none;
                    color: rgba(0,0,0,.26);
                    box-shadow: none !important;
                    cursor: default;
                    pointer-events: none;
                }
                &:first-child{
                    margin-right: 20px;
                }
                &:hover{
                    background: $orange;
                }
            }
        }
        &__send{
            background: $button-background-colored;
            border: none !important;
            &:hover{
                background: $button-background-colored-hover;
            }
        }
        &__progress{
            position: absolute;
            height: 4px;
            width: 100%;
            top: 0;
            left: 0;
            background: #EAEFF2;
            span{
                // background: $border-gradient;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
            }
        }
        .btn-base.btn-base_normal.default-button {
            width: 83.33px;
            min-width: 83.33px;
        }
    }

</style>
