import RevisionsLayout from '@/layouts/RevisionsLayout';
import RevisionsHeader from '@/components/CustomRevisions/RevisionsHeader';
import RevisionsList from '@/components/CustomRevisions/RevisionsList';
import RevisionsViewerPost from '@/components/CustomRevisions/RevisionsViewerPost';
import RevisionsViewerCompare from '@/components/CustomRevisions/RevisionsViewerCompare';
import ConfirmModal from '@/components/modals/ConfirmModal';
import * as jsdiff from 'diff';
import myWorker from '@/workers'

export default {
    components: {
        RevisionsLayout,
        RevisionsHeader,
        RevisionsList,
        RevisionsViewerPost,
        RevisionsViewerCompare,
        ConfirmModal
    },
    data() {
        return {
            compareData: null,
            loader: false,
            loaderMoreCount: 0,
            loadMoreInProgress: false,
            diffCounter: 0
        }
    },
    watch: {
        getSecondPost() {
            if (!this.getSecondPost) {
                this.compareData = null
                return
            }
            this.computedDifferent(this.getFirstPost, this.getSecondPost)
        }
    },
    computed: {
        versionOfFirstPost() {
            const index = this.data.findIndex((item) => item.id === this.selectedItems[0])
            return this.revisions.length - index
        },
        getCurrentRevisionTitle() {
            const revision = this.revisions.find((item) => item.id === this.post.revision_id)
            if (revision.post_title_primary) {
                return revision.post_title_primary
            }
            return revision.post_title
        },
        versionOfSecondPost() {
            const index = this.data.findIndex((item) => item.id === this.selectedItems[1])
            return this.revisions.length - index
        },
        getFirstPost() {
            return this.data.find((item) => item.id === this.selectedItems[0])
        },
        getSecondPost() {
            return this.data.find((item) => item.id === this.selectedItems[1])
        },
        getFirstPostContent() {
            if (!this.getFirstPost) return ''
            return this.getFirstPost.post_content_primary || this.getFirstPost.post_content
        },
        getSecondPostContent() {
            if (!this.getSecondPost) return ''
            return this.getSecondPost.post_content_primary || this.getSecondPost.post_content
        },
        hasLoadMore() {
            return this.getPostData(this.getFirstPost)?.length > (this.loaderMoreCount + 1) * 7000 || this.getPostData(this.getSecondPost)?.length > (this.loaderMoreCount + 1) * 7000
        }
    },
    methods: {
        getWordCount(post) {
            return post ? post.wordcount : null
        },
        removeRevisionView(id) {
            this.loader = false
            this.selectedItems.splice(id, 1)
        },
        // eslint-disable-next-line no-unused-vars
        getFieldData(post, field, label) {
            return `
                <div class="post-data__block ${label === 'Content' || label === 'Description' ? 'fr-view' : ''}">
                    <div class="post-data__block--label">${label}</div>
                    <div class="post-data__block--text">${post[field] || '----'}</div>
                </div>
            `
        },
        getPostData(post) {
            let content = ''
            if (!post) return
            // eslint-disable-next-line no-return-assign
            this.filteredFields.forEach((item) => content += this.getFieldData({ ...this.post, ...post }, item.preview_field, item.label))
            // eslint-disable-next-line consistent-return
            return content
        },
        loadMore() {
            if (window.Worker) {
                if (this.loadMoreInProgress || !this.hasLoadMore) { return }
                this.loaderMoreCount += 1
                this.loadMoreInProgress = true
                myWorker.send({ oldPost: this.getPostData(this.getFirstPost).substring(this.loaderMoreCount * 7000, (this.loaderMoreCount + 1) * 7000), newPost: this.getPostData(this.getSecondPost).substring(this.loaderMoreCount * 7000, (this.loaderMoreCount + 1) * 7000), selectedItems: [...this.selectedItems] })
                    .then(({ diff, selectedItems }) => {
                        if (this.selectedItems[0] !== selectedItems[0] || this.selectedItems[1] !== selectedItems[1]) { return }
                        this.loader = false
                        console.log('test 1')
                        diff.forEach((part) => {
                            // eslint-disable-next-line no-plusplus
                            if (part.added || part.removed) this.diffCounter++
                            // eslint-disable-next-line no-nested-ternary
                            const color = part.added ? 'green-revision__text' : part.removed ? 'red-revision__text' : '';
                            const span = `<span class="${color}">${part.value}</span>`
                            this.compareData += span
                        });
                        this.loadMoreInProgress = false
                        // const div = `

                        // `
                        // this.compareData = this.compareData
                    })
            }
        },
        computedDifferent(oldPost, newPost) {
            this.loader = true
            this.loaderMoreCount = 0
            if (window.Worker) {
                myWorker.send({ oldPost: this.getPostData(oldPost).substring(0, 7000), newPost: this.getPostData(newPost).substring(0, 7000), selectedItems: [...this.selectedItems] })
                    .then(({ diff, selectedItems }) => {
                        if (this.selectedItems[0] !== selectedItems[0] || this.selectedItems[1] !== selectedItems[1]) { return }
                        this.loader = false
                        this.compareData = ''
                        this.diffCounter = 0
                        diff.forEach((part) => {
                            // eslint-disable-next-line no-plusplus
                            if (part.added || part.removed) this.diffCounter++
                            // eslint-disable-next-line no-nested-ternary
                            const color = part.added ? 'green-revision__text' : part.removed ? 'red-revision__text' : '';
                            const span = `<span class="${color}">${part.value}</span>`
                            this.compareData += span
                        });
                    })
            } else {
                const diff = jsdiff.diffWords(this.getPostData(oldPost), this.getPostData(newPost));

                this.compareData = ''
                // let block = '<div class="compareData-block">replacePlace</div>'
                let diffCounter = 0
                diff.forEach((part) => {
                    // eslint-disable-next-line no-plusplus
                    if (part.added || part.removed) diffCounter++
                    // eslint-disable-next-line no-nested-ternary
                    const color = part.added ? 'green-revision__text' : part.removed ? 'red-revision__text' : '';
                    const span = `<span class="${color}">${part.value}</span>`
                    this.compareData += span
                });
                const div = `
                    <div class="post-data__block">
                        <div class="post-data__block--differences">
                            Differences between versions
                            <span>${diffCounter}</span>
                        </div>
                    </div>
                `
                this.compareData = div + this.compareData
            }
        }
    }
}
