<template>
    <svg
        width="63"
        height="63"
        viewBox="0 0 63 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0)">
            <path
                d="M53.8125 63H22.3125C18.6953 63 15.75 60.0574 15.75 56.4375V17.0625C15.75 13.4426 18.6953 10.5 22.3125 10.5H53.8125C57.4298 10.5 60.375 13.4426 60.375 17.0625V56.4375C60.375 60.0574 57.4298 63 53.8125 63ZM22.3125 13.125C20.1416 13.125 18.375 14.8916 18.375 17.0625V56.4375C18.375 58.6084 20.1416 60.375 22.3125 60.375H53.8125C55.9834 60.375 57.75 58.6084 57.75 56.4375V17.0625C57.75 14.8916 55.9834 13.125 53.8125 13.125H22.3125Z"
                fill="#4F4F4F"
            />
            <path
                d="M11.8125 55.125H9.1875C5.57025 55.125 2.625 52.1824 2.625 48.5625V6.5625C2.625 2.94263 5.57025 0 9.1875 0H40.6875C44.3048 0 47.25 2.94263 47.25 6.5625C47.25 7.287 46.662 7.875 45.9375 7.875C45.213 7.875 44.625 7.287 44.625 6.5625C44.625 4.39162 42.8584 2.625 40.6875 2.625H9.1875C7.01663 2.625 5.25 4.39162 5.25 6.5625V48.5625C5.25 50.7334 7.01663 52.5 9.1875 52.5H11.8125C12.537 52.5 13.125 53.088 13.125 53.8125C13.125 54.537 12.537 55.125 11.8125 55.125Z"
                fill="#4F4F4F"
            />
            <path
                d="M48.5625 44.625H27.5625C26.838 44.625 26.25 44.037 26.25 43.3125C26.25 42.588 26.838 42 27.5625 42H48.5625C49.287 42 49.875 42.588 49.875 43.3125C49.875 44.037 49.287 44.625 48.5625 44.625Z"
                fill="#4F4F4F"
            />
            <path
                d="M48.5625 55.125H27.5625C26.838 55.125 26.25 54.537 26.25 53.8125C26.25 53.088 26.838 52.5 27.5625 52.5H48.5625C49.287 52.5 49.875 53.088 49.875 53.8125C49.875 54.537 49.287 55.125 48.5625 55.125Z"
                fill="#4F4F4F"
            />
            <path
                d="M48.5625 34.125H27.5625C26.838 34.125 26.25 33.537 26.25 32.8125C26.25 32.088 26.838 31.5 27.5625 31.5H48.5625C49.287 31.5 49.875 32.088 49.875 32.8125C49.875 33.537 49.287 34.125 48.5625 34.125Z"
                fill="#4F4F4F"
            />
            <path
                d="M48.5625 23.625H27.5625C26.838 23.625 26.25 23.037 26.25 22.3125C26.25 21.588 26.838 21 27.5625 21H48.5625C49.287 21 49.875 21.588 49.875 22.3125C49.875 23.037 49.287 23.625 48.5625 23.625Z"
                fill="#4F4F4F"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="63"
                    height="63"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'File'
}
</script>

<style scoped>

</style>
