<template>
    <div
        class="revisions-list__item"
        :class="{ 'selected-revision': checkValue }"
    >
        <form-checkbox
            class="revisions-list__item-checkbox"
            :value="checkValue"
            @input="clickHandler"
        />
        <div class="revisions-list__item-info">
            <div class="revisions-list__item-version">
                <p>
                    Version {{ id }}
                    <status-ok
                        v-if="active"
                    />
                </p>
                <div class="revisions-list__item--block">
                    <div
                        class="comments-count revisions-list__item--block__item"
                    >
                        <div
                            v-if="item.reviews_count > 0"
                            class="comments-count__circle"
                            @click="openReviewsModal"
                        >
                            {{ item.reviews_count }}
                        </div>
                    </div>
                    <div
                        class="comment-star revisions-list__item--block__item"
                        @click="openFeedbackModal"
                    >
                        <star />
                    </div>
                    <div
                        class="revisions-list__item-version--icons revisions-list__item--block__item"
                        @click="openDeleteModal"
                    >
                        <garbage
                            v-if="!active"
                        />
                    </div>
                </div>
            </div>
            <div class="revisions-list__item-updated">
                <p class="updated_at">
                    <span>{{ item.created_at | moment_from }} ago</span>
                    <span>{{ item.wordcount }} words</span>
                </p>
                <div class="updated_by">
                    <span>by</span>
                    <div
                        v-if="!item.avatar_url"
                        class="updated_by-user__icon"
                    >
                        {{ getShortName }}
                    </div>
                    <div
                        v-else
                        class="updated_by-user__icon"
                    >
                        <img :src="item.avatar_url">
                    </div>
                    <span>{{ item.firstname }} {{ item.lastname }}</span>
                </div>
            </div>
        </div>
        <div
            class="revisions-action__buttons"
        >
            <custom-button
                v-if="!active && post.locked !== 1"
                default
                :width="'105px'"
                :height="'40px'"
                class="blue-button"
                @on-btn-click="openActivateModal"
            >
                Activate
            </custom-button>
            <custom-button
                default
                :width="active || post.locked === 1 ? '100%' : '105px'"
                :height="'40px'"
                class="grey-button"
                @on-btn-click="openPreviewModal"
            >
                View
            </custom-button>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin'
import Garbage from '@/icons/Garbage';
import StatusOk from '@/icons/StatusOk';
import Star from '@/icons/Star';

export default {
    name: 'RevisionsList',
    components: {
        Garbage,
        StatusOk,
        Star
    },
    mixins: [
        filtersMixin
    ],
    props: {
        post: Object,
        item: Object,
        id: Number,
        selectedItems: Array,
        active: Boolean,
        reviewsType: String
    },
    computed: {
        checkValue() {
            return this.selectedItems.findIndex((id) => id === this.item.id) !== -1
        },
        getShortName() {
            if (this.item.firstname && this.item.lastname) {
                return this.item.firstname[0] + this.item.lastname[0]
            } if (this.item.firstname) {
                return this.item.firstname[0]
            } if (this.item.lastname) {
                return this.item.lastname[0]
            }
            return ''
        }
    },
    methods: {
        openActivateModal() {
            if (this.post.locked === 1) return
            this.$bus.$emit('openModal', 'activate-modal', { id: this.item.id })
        },
        openDeleteModal() {
            this.$bus.$emit('openModal', 'delete-modal', { id: this.item.id })
        },
        openPreviewModal() {
            this.$bus.$emit('openModal', 'preview-modal', { post: { ...this.post, ...this.item, rev_id: this.item.id } })
        },
        clickHandler() {
            this.$emit('selectItem', this.item.id)
        },
        openFeedbackModal() {
            this.$bus.$emit('openModal', 'feedback-modal', {
                post: {
                    ...this.post,
                    ...this.item,
                    rev_id: this.item.id,
                    type: this.reviewsType
                }
            })
        },
        openReviewsModal() {
            this.$bus.$emit('openModal', 'reviews-modal', {
                post: {
                    ...this.post,
                    ...this.item,
                    rev_id: this.item.id,
                    type: this.reviewsType
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .revisions-list__item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #F2F2F2;
        &--block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90px;
            height: 30px;
            &__item {
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &-checkbox {
            margin-right: 12px;
            margin-top: 6px;
        }
        &-info {
            width: calc(100% - 30px);
        }
        &-version {
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #1f2939;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &--icons {
                svg {
                    cursor: pointer;
                    height: 23px;
                    width: 17px;
                    color: #4F4F4F;
                    transition: .3s;
                    &:hover {
                        color: $orange;
                    }
                }
            }
            p {
                display: flex;
                align-items: center;
                min-height: 24px;
                svg {
                    margin-left: 8px;
                }
            }
        }
        &-updated {
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #818181;
            .updated_at {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        .updated_by-user__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin: 0 7px;
            border-radius: 50%;
            font-size: 8px;
            background-color: rgba(255, 84, 35, 0.7);
            color: #4F4F4F;
            svg {
                color: #4F4F4F;
            }
            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
        .updated_at {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 6px;
            margin-top: 4px;
        }
        .updated_by {
            display: flex;
        }
        .revisions-action__buttons {
            margin-top: 12px;
            width: 100%;
            display: flex;
            button {
                margin-right: 12px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .comments-count {
        &__circle {
            width: 30px;
            height: 30px;
            background: rgba(0, 232, 51, 0.1);
            border-radius: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 18px;
            color: #219653;
            cursor: pointer;
        }
    }
    .comment-star {
        transition: .3s;
        cursor: pointer;
        &:hover {
            color: $orange;
        }
    }
    .selected-revision {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -12px;
            left:  -20px;
            width: 3px;
            height: calc(100% + 12px);
            background: $orange;
        }
    }
</style>
