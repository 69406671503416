<template>
    <div v-if="loading">
        loading
    </div>
    <revisions-layout
        v-else
        :revisions_count="revisions.length"
    >
        <template #header>
            <revisions-header
                :url="post.url"
                :title="getCurrentRevisionTitle"
            />
        </template>
        <template #list>
            <revisions-list
                v-for="(revision, id) in revisions"
                :id="revisions.length - id"
                :key="revisions.length - id"
                :item="revision"
                :post="post"
                :active="post.revision_id === revision.id"
                :selected-items="selectedItems"
                :reviews-type="reviewsType"
                @selectItem="addSelectedItem($event)"
                @activateRevision="activateRevision($event)"
                @deleteRevision="deleteRevision($event)"
            />
        </template>
        <template slot="post-one">
            <revisions-viewer-post
                :version="versionOfFirstPost"
                :post="getPostData(getFirstPost)"
                :words_count="getWordCount(getFirstPost)"
                :data="getFirstPost"
                @resetRevisionView="removeRevisionView(0)"
            />
        </template>
        <template slot="post-two">
            <revisions-viewer-post
                :version="versionOfSecondPost"
                :post="getPostData(getSecondPost)"
                :words_count="getWordCount(getSecondPost)"
                :data="getSecondPost"
                @resetRevisionView="removeRevisionView(1)"
            />
        </template>
        <template #compare>
            <revisions-viewer-compare
                :loader="loader"
                :compare-data="compareData"
                :diff-counter="diffCounter"
                :has-load-more="hasLoadMore"
                @load-more="loadMore"
            />
        </template>
        <template #modals>
            <confirm-modal
                :title="`Delete Revision?`"
                :content="'Are you sure'"
                :name="'delete-modal'"
                @onConfirmClick="deleteRevision($event)"
            />
            <confirm-modal
                :title="`Activate Revision?`"
                :content="'Are you sure'"
                :name="'activate-modal'"
                @onConfirmClick="activateRevision($event)"
            />
            <preview-revision-modal
                :name="'preview-modal'"
                :fields="filteredFields"
                :revisions="data"
            />
            <feedback-modal
                :name="'feedback-modal'"
            />
            <reviews-modal
                :name="'reviews-modal'"
            />
        </template>
    </revisions-layout>
</template>

<script>
import customRevisionsMixins from '@/mixins/revisions/customRevisionsMixins';
import PreviewRevisionModal from '@/components/modals/PreviewRevisionModal';
import FeedbackModal from '@/components/modals/FeedbackModal';
import ReviewsModal from '@/components/modals/ReviewsModal';

export default {
    name: 'Index',
    components: {
        PreviewRevisionModal,
        FeedbackModal,
        ReviewsModal
    },
    mixins: [
        customRevisionsMixins
    ],
    props: {
        fields: Array,
        item: Object,
        posts: Object,
        selectedItems: Array,
        loading: Boolean,
        reviewsType: String
    },
    computed: {
        post() {
            return this.item.post
        },
        revisions() {
            return this.item.revisions
        },
        data() {
            if (!this.item) return []
            return this.revisions
        },
        filteredFields() {
            return this.fields?.filter((field) => field.hideComponent !== true)
        }
    },
    watch: {
        selectedItems: {
            deep: true,
            handler() {
                if (this.selectedItems.length === 2) { return }
                this.loader = false
            }
        }
    },
    activated() {
        this.$emit('getRevisions', { id: this.$route.params.id })
    },
    methods: {
        deleteRevision(event) {
            this.$emit('deleteRevision', event)
        },
        activateRevision(event) {
            this.$emit('activateRevision', event)
        },
        addSelectedItem(event) {
            this.$emit('addSelectedItem', event)
        }
    }
}
</script>

<style lang="scss">
    .post-data__block {
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 15px;
        padding-bottom: 15px;
        &--label {
            font-size: 14px;
            line-height: 18px;
            color: #818181;
        }
        &--text {
            font-size: 14px;
            line-height: 18px;
            color: #1F2939;
        }
        &--differences {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            color: #1F2939;
            display: flex;
            align-items: center;
            & > span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                // width: 38px;
                padding: 0 5px;
                height: 38px;
                background: rgba(79, 79, 79, 0.1);
                border-radius: 4px;
            }
        }
    }
    .green-revision__text {
        background: green;
        color: #fff !important;
    }
    .red-revision__text {
        background: red;
        color: #fff !important;
    }
</style>
