// eslint-disable-next-line import/no-extraneous-dependencies
import PromiseWorker from 'promise-worker'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./worker'

const worker = new Worker()
const promiseWorker = new PromiseWorker(worker)

const send = (message) => promiseWorker.postMessage({
    type: 'message',
    message
})

export default {
    send
}
