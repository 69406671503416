export const TABS = [
    {
        active: false,
        name: 'Links',
        title: 'Was the project delivered on time?',
        question: 'Describe your problem',
        variableName: 'chk_links',
        textField: 'comment_links'
    },
    {
        active: false,
        name: 'Content',
        title: 'Was the content of good quality?',
        question: 'Describe your problem',
        variableName: 'chk_content',
        textField: 'comment_content'
    },
    {
        active: false,
        name: 'Grammar',
        title: 'Did the writer communicate well?',
        question: 'Describe your problem',
        variableName: 'chk_grammar',
        textField: 'comment_grammar'
    },
    {
        active: false,
        name: 'Photo',
        title: 'How would you rate our website?',
        question: 'Describe your problem',
        variableName: 'chk_photo',
        textField: 'comment_photo'
    }
]
