<template>
    <div class="revisions-header">
        <a
            :href="url"
            target="_blank"
        >
            <p class="revisions-header__title">{{ title }}</p>
            <span class="revisions-header__url">{{ url }}</span>
        </a>
        <span
            class="revisions-header__cross"
            @click="onCrossClick"
        >
            <cross />
        </span>
    </div>
</template>

<script>
import Cross from '@/icons/Cross';

export default {
    name: 'RevisionsHeader',
    components: {
        Cross
    },
    props: {
        url: String,
        title: String
    },
    methods: {
        onCrossClick() {
            this.$router.back()
        }
    }
}
</script>

<style scoped lang="scss">
    .revisions-header {
        height: 80px;
        margin-bottom: 16px;
        padding: 16px;
        position: relative;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &__title {
            width: 100%;
            font-size: 20px;
            margin-bottom: 4px;
            color: #1e2838;
            font-weight: bold;
        }
        &__url {
            width: 100%;
            color: #1976d2;
            font-size: 14px;
            line-height: 18px;
        }
        &__cross {
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -7px
        }
    }
</style>
