<template>
    <div class="feedback-modal__buttons">
        <custom-button
            type="button"
            class="ok-button"
            :class="{'active': value}"
            @on-btn-click="buttonHandler(true)"
        >
            <status-ok-bold />
        </custom-button>
        <custom-button
            type="button"
            class="delete-button"
            :class="{'active': value === false}"
            @on-btn-click="buttonHandler(false)"
        >
            <status-no />
        </custom-button>
    </div>
</template>

<script>
import StatusOkBold from '@/icons/StatusOkBold';
import StatusNo from '@/icons/StatusNo';

export default {
    name: 'FeedbackButtons',
    components: {
        StatusOkBold,
        StatusNo
    },
    props: {
        value: Boolean
    },
    methods: {
        buttonHandler(val) {
            this.$emit('buttonClick', val)
        }
    }
}
</script>

<style lang="scss">
.feedback-modal__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    button {
        width: 48%;
    }
    .ok-button {
        background: rgba(33, 150, 83, 0.1);
        border-radius: 4px;
        color: #219653;
        &.active {
            background: rgba(33, 150, 83, 1);
            svg {
                color: #ffffff;
            }
        }
    }
    .delete-button {
        background: rgba(255, 0, 0, 0.1);
        border-radius: 4px;
        color: #ff2400;
        &.active {
            background: rgba(255, 0, 0, 1);
            color: #ffffff;
        }
    }
}
</style>
