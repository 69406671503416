<template>
    <modal-layout
        v-if="dialog"
        :size="'large'"
        class="preview-revision__modal"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
    >
        <template #header>
            <div class="v-modal__revisions-header">
                <h2>Post Version {{ getVersion }}</h2>
                <p>By {{ data.post.firstname }} {{ data.post.lastname }}</p>
            </div>
        </template>
        <template #content>
            <slot name="content">
                <preview
                    :form_data="data.post"
                    :fields="fields"
                />
            </slot>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import Preview from '@/components/CustomEditor/Preview'

export default {
    name: 'PreviewRevisionModal',
    components: {
        Preview
    },
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        fields: Array,
        name: String,
        revisions: Array
    },
    computed: {
        getVersion() {
            const index = this.revisions.findIndex((rev) => this.data.post.rev_id === rev.id)
            return this.revisions.length - index
        }
    }
}
</script>

<style lang="scss">
.v-modal__header {
    .v-modal__revisions-header {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        p {
            width: 100%;
        }
        span {
            width: 100%;
        }
        p, span {
            color: #818181;
        }
    }
}
.v-modal.preview-revision__modal {
    padding: 0;
    .v-modal__content {
        margin-bottom: 0 !important;
        padding: 0px 20px;
    }
    .v-modal__buttons {
        margin-bottom: 0 !important;
    }
}
</style>
