import {
    requiredIf,
    minLength,
    maxLength,
    required
} from 'vuelidate/lib/validators';

export const validationRules = {
    chk_links: {
        required
    },
    comment_links: {
        required: requiredIf(function () {
            return !!(this.activeTab === 0 && this.chk_links === false)
        }),
        minLength: minLength(20),
        maxLength: maxLength(1000)
    },
    chk_content: {
        required
    },
    comment_content: {
        required: requiredIf(function () {
            return !!(this.activeTab === 1 && this.chk_content === false)
        }),
        minLength: minLength(20),
        maxLength: maxLength(1000)
    },
    chk_grammar: {
        required
    },
    comment_grammar: {
        required: requiredIf(function () {
            return !!(this.activeTab === 2 && this.chk_grammar === false)
        }),
        minLength: minLength(20),
        maxLength: maxLength(1000)
    },
    chk_photo: {
        required
    },
    comment_photo: {
        required: requiredIf(function () {
            return !!(this.activeTab === 3 && this.chk_photo === false)
        }),
        minLength: minLength(20),
        maxLength: maxLength(1000)
    }
};
